/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        // JavaScript to be fired on all pages
        $(document).on("click", ".nav-toggle-button", function () {
          $("html, body").toggleClass("active-nav");
        });

        $(document).on("click", ".active-nav .nav-toggle-button", function () {
          $(".menu-mobile").removeClass("active");
        });

        $(document).on("click", ".menu-mobile-back", function () {
          $(".menu-mobile").removeClass("active");
        });

        $(document).on("click", "#filter-toggle", function () {
          $("html, body").toggleClass("filter-toggle");
        });
        $(document).on("click", "#filter-close", function () {
          $("html, body").toggleClass("filter-toggle");
        });
        jQuery("#lang_choice_1, #lang_choice_2").select2({
          minimumResultsForSearch: Infinity,
          id: "navselect",
        });
        $("#lang_choice_1, #lang_choice_2").change(function (e) {
          window.location = e.currentTarget.value;
        });
        $(window).scroll(function () {
          var scroll = $(window).scrollTop();

          if (scroll >= 50) {
            $("body").addClass("nav-scrolled");
          } else {
            $("body").removeClass("nav-scrolled");
          }
        });

        $(document).ready(function () {
          if ($("body").hasClass("search")) {
            var searchType = $("#search-page-inner").attr("data-search");
            $("#post-type-input").val(searchType);
          }

          $("#search-toggle").click(function () {
            $("body").addClass("active-search");
            $("#search-block").addClass("active");
            $("#search-block").removeClass("inactive");
          });

          $(document).on("click", "#search-close", function () {
            $("body").removeClass("active-search");
            $("#search-block").removeClass("active");
            $("#search-block").addClass("inactive");
          });
        });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
        // initialize animations
        AOS.init();

        const swiper = new Swiper("#products-slider .swiper-container", {
          // Optional parameters
          loop: false,
          slidesPerView: 4,
          spaceBetween: 24,

          watchOverflow: true,
          navigation: {
            nextEl: "#products-slider .swiper-button-next",
            prevEl: "#products-slider .swiper-button-prev",
          },
          pagination: {
            el: "#products-slider .swiper-pagination",
            dynamicBullets: true,
          },
        });

        /* MEGAMENU */
        var timerwheels;
        var timerrims;
        var timersupport;
        var timeoverlay;

        /* Submenu Wheels*/
        $("li.submenu.wheels")
          .on("mouseover", function () {
            clearTimeout(timerwheels);
            clearTimeout(timeoverlay);
            openSubmenuwheels();
          })
          .on("mouseleave", function () {
            timerwheels = setTimeout(closeSubmenuwheels, 200);
            timeoverlay = setTimeout(closeoverlay, 200);
          });
        $("#menu-full-wheels")
          .on("mouseover", function () {
            clearTimeout(timerwheels);
            clearTimeout(timeoverlay);
          })
          .on("mouseleave", function () {
            timerwheels = setTimeout(closeSubmenuwheels, 200);
            timeoverlay = setTimeout(closeoverlay, 200);
          });

        function openSubmenuwheels() {
          clearTimeout(timerwheels);
          clearTimeout(timeoverlay);
          $("#menu-full-wheels").addClass("opened");
          $("li.submenu.wheels").addClass("open");
          $("header").addClass("opened");
        }

        function closeSubmenuwheels() {
          $("#menu-full-wheels").removeClass("opened");
          $("li.submenu.wheels").removeClass("open");
        }

        /* Submenu Support */
        $("li.submenu.support")
          .on("mouseover", function () {
            clearTimeout(timersupport);
            clearTimeout(timeoverlay);
            openSubmenusupport();
          })
          .on("mouseleave", function () {
            timersupport = setTimeout(closeSubmenusupport, 200);
            timeoverlay = setTimeout(closeoverlay, 200);
          });
        $("#menu-full-support")
          .on("mouseover", function () {
            clearTimeout(timersupport);
            clearTimeout(timeoverlay);
          })
          .on("mouseleave", function () {
            timersupport = setTimeout(closeSubmenusupport, 200);
            timeoverlay = setTimeout(closeoverlay, 200);
          });

        function openSubmenusupport() {
          clearTimeout(timerwheels);
          clearTimeout(timeoverlay);
          $("#menu-full-support").addClass("opened");
          $("li.submenu.support").addClass("open");
          $("header").addClass("opened");
        }

        function closeSubmenusupport() {
          $("#menu-full-support").removeClass("opened");
          $("li.submenu.support").removeClass("open");
        }

        /* Submenu Mobile Wheels*/
        $("li.mobile_submenu.wheels").on("click", function () {
          $("#menu-mobile-wheels").addClass("active");
        });

        /* Submenu Rims*/
        $("li.submenu.rims")
          .on("mouseover", function () {
            clearTimeout(timerrims);
            clearTimeout(timeoverlay);
            openSubmenurims();
          })
          .on("mouseleave", function () {
            timerrims = setTimeout(closeSubmenurims, 200);
            timeoverlay = setTimeout(closeoverlay, 200);
          });
        $("#menu-full-rims")
          .on("mouseover", function () {
            clearTimeout(timerrims);
            clearTimeout(timeoverlay);
          })
          .on("mouseleave", function () {
            timerrims = setTimeout(closeSubmenurims, 200);
            timeoverlay = setTimeout(closeoverlay, 200);
          });

        function openSubmenurims() {
          clearTimeout(timerrims);
          clearTimeout(timeoverlay);
          $("#menu-full-rims").addClass("opened");
          $("li.submenu.rims").addClass("open");
          $("header").addClass("opened");
        }

        function closeSubmenurims() {
          $("#menu-full-rims").removeClass("opened");
          $("li.submenu.rims").removeClass("open");
        }

        function closeoverlay() {
          $("header").removeClass("opened");
        }

        /* Submenu Mobile Rims*/
        $("li.mobile_submenu.rims").on("click", function () {
          $("#menu-mobile-rims").addClass("active");
        });

        //video animation
        $(".family-inner").on("mouseover", function () {
          var thisVid = $(this).find("video.family-video");
          //$(thisVid).css('opacity',1);
          $(this).addClass("active");
          $(thisVid).get(0).play();
        });

        $(".family-inner").on("mouseout", function () {
          var thisVid = $(this).find("video.family-video");
          //$(thisVid).css('opacity',0);
          $(this).removeClass("active");
          $(thisVid).get(0).pause();
        });

        //video animation
        $(".inner-content").on("mouseover", function () {
          var thisVid = $(this).find("video.video");
          $(this).addClass("active");
          $(thisVid).get(0).play();
        });

        $(".inner-content").on("mouseout", function () {
          var thisVid = $(this).find("video.video");
          $(this).removeClass("active");
          $(thisVid).get(0).pause();
        });

        $(".circle-hover").on("mouseover", function () {
          $(this).addClass("hovering");
        });

        $(".circle-hover").on("mouseout", function () {
          $(this).removeClass("hovering");
        });

        // Scroll Navigate Home Slides
        var photoSwiper = new Swiper("#photo-slider", {
          init: false,
          speed: 800,
          loop: false,
          navigation: {
            nextEl: ".right-nav-slider",
            prevEl: ".left-nav-slider",
          },
          on: {
            slideChangeTransitionEnd: function () {
              /* do something */
              var realIndex = photoSwiper.activeIndex + 1;
              $(".itemcount.active").html(realIndex);
            },
          },
        });
        $(".hover-group").click(function () {
          $("#photo-slider-outer").delay(200).show();
          $("html").addClass("over");
          photoSwiper.init();
          var clickTarget = $(this).attr("data-id");
          photoSwiper.slideTo(clickTarget, 0);
        });
        $(document).on("click", "#slider-close", function () {
          $("#photo-slider-outer").hide();
          $("html").removeClass("over");
        });
        $(document).on("click", "#slider-overlay", function () {
          $("#photo-slider-outer").hide();
          $("html").removeClass("over");
        });

        /* LOADER VIDEO */
        /*if ($("#loader_video").length > 0) {
          $(document).ready(function () {
            var video = document.getElementById('loader_video');

            video.addEventListener('ended', function (event) {
              var data = {
                'action': 'loader_cookie',
                'post_type': 'POST'
              };

              $.post(ajaxurl, data, function () {
                $(".loader-wrap").fadeOut(500, function () {
                  $(this).remove();
                });
              });
            });
          });
        }*/
        if ($(".loader-wrap").length > 0) {
          $(document).ready(function () {
            setTimeout(function () {
              var data = {
                action: "loader_cookie",
                post_type: "POST",
              };

              $.post(ajaxurl, data, function () {
                $(".loader-wrap").fadeOut(500, function () {
                  $(this).remove();
                });
              });
            }, 3000);
          });
        }

        /* WHEEL FINDER */
        if ($("section.wheel-finder-wrapper").length > 0) {
          var prev_1_sel = "";
          var prev_2_sel = "";
          var prev_3_sel = "";
          var prev_4_sel = "";
          var percent = 0;
          var wheelFinderSwiper;

          // on option select load next question or present results
          $("body").on(
            "click",
            ".questions-wrapper .option-wrapper",
            function () {
              var question = $(this).find("span").data("question");
              var value = $(this).find("span").data("value");
              var name = $(this).find("span").data("name");
              var lang = $(this).find("span").data("lang");

              if (
                (question == 1 && prev_1_sel != value) ||
                (question == 2 && prev_2_sel != value) ||
                (question == 3 && prev_3_sel != value) ||
                (question == 4 && prev_4_sel != value)
              ) {
                $("#question_" + question)
                  .find("span")
                  .removeClass("selected");
                $(this).find("span").addClass("selected");
                $(".question-wrapper").slice(question).remove();

                if (question == 1) $("input#question_1_sel").val(value);
                if (question == 2) $("input#question_2_sel").val(value);
                if (question == 3) $("input#question_3_sel").val(value);
                if (question == 4) $("input#question_4_sel").val(value);

                var data = {
                  action: "get_next_options",
                  post_type: "POST",
                  question: question,
                  value: value,
                  lang: lang,
                  question_1_option: $("input#question_1_sel").val(),
                  question_2_option: $("input#question_2_sel").val(),
                  question_3_option: $("input#question_3_sel").val(),
                  question_4_option: $("input#question_4_sel").val(),
                };

                $.post(ajaxurl, data, function (response) {
                  var spl = response.split(";;");
                  if (spl[0] > 0) {
                    // show next question options
                    $(".question-wrapper").slice(-question).fadeOut();
                    $("#div_results").append(spl[1]);
                    if (question == 1) prev_1_sel = value;
                    if (question == 2) prev_2_sel = value;
                    if (question == 3) prev_3_sel = value;
                    if (question == 4) prev_4_sel = value;

                    percent += 25;
                    if ($(window).width() <= 767) {
                      // if window with less or equal to 767 the inverted percentage bar and "width" will be changed
                      $(".wheel-finder-percentage .progress div").css(
                        "width",
                        percent + "%"
                      );
                    } else {
                      // else the percentage bar is vertical and "height" will be changed
                      $(".wheel-finder-percentage .progress div").css(
                        "height",
                        percent + "%"
                      );
                    }
                    $(".wheel-finder-percentage i").html(percent + " %");

                    $(
                        ".wheel-finder-steps .steps-wrapper span#step_" + question
                      )
                      .html(name)
                      .addClass("filled");

                    setTimeout(function () {
                      AOS.init();
                    }, 500);
                  } else {
                    // show products
                    var selected = [];
                    $(".question-wrapper span.selected").each(function () {
                      selected.push($(this).data("value"));
                    });

                    var data2 = {
                      action: "get_wheel_finder_products",
                      post_type: "POST",
                      selected: selected,
                    };

                    $.post(ajaxurl, data2, function (response2) {
                      if (response2) {
                        $("#div_products").html(response2);

                        $(".text-wrapper").addClass("bg-gray");
                        $(".wheel-finder-wrapper").addClass("bg-white");

                        percent = 100;
                        if ($(window).width() <= 767) {
                          // if window with less or equal to 767 the inverted percentage bar and "width" will be changed
                          $(".wheel-finder-percentage .progress div").css(
                            "width",
                            percent + "%"
                          );
                        } else {
                          // else the percentage bar is vertical and "height" will be changed
                          $(".wheel-finder-percentage .progress div").css(
                            "height",
                            percent + "%"
                          );
                        }
                        $(".wheel-finder-percentage i").html(percent + " %");

                        wheelFinderSwiper = new Swiper(
                          ".wheel-finder-products-list", {
                            slidesPerView: 3,
                            watchOverflow: true,
                            pagination: {
                              el: ".wheel-finder-products-list .swiper-pagination",
                            },
                            navigation: {
                              nextEl: ".wheel-finder-products-list .swiper-button-next",
                              prevEl: ".wheel-finder-products-list .swiper-button-prev",
                            },
                            breakpoints: {
                              320: {
                                slidesPerView: 1,
                                spaceBetween: 16,
                              },
                              1000: {
                                slidesPerView: 3,
                                spaceBetween: 32,
                              },
                            },
                          }
                        );

                        $(".question-wrapper").slice(-question).fadeOut();
                        $(".wheel-finder-percentage").hide();
                        $(".products-wrapper").fadeIn();
                        $(
                            ".wheel-finder-steps .steps-wrapper span#step_" +
                            question
                          )
                          .html(name)
                          .addClass("filled");

                        setTimeout(function () {
                          AOS.init();
                          window.dispatchEvent(new Event("resize"));
                        }, 500);
                      }
                    });
                  }
                  if (question == 1) {
                    $(".wheel-finder-wrapper .wheel-finder-steps").fadeIn();
                    $(
                      ".wheel-finder-wrapper .wheel-finder-percentage"
                    ).addClass("padd-top");
                  }
                });
              }
            }
          );

          // clean steps after select one
          $("body").on(
            "click",
            ".wheel-finder-wrapper .wheel-finder-steps span.step",
            function () {
              var id = $(this).attr("id").split("_");
              var question = id[1];

              if (question == 1) {
                prev_1_sel = "";
                prev_2_sel = "";
                prev_3_sel = "";
                prev_4_sel = "";
                percent = 0;
                $("input#question_1_sel").val("");
                $("input#question_2_sel").val("");
                $("input#question_3_sel").val("");
                $("input#question_4_sel").val("");
              } else {
                if (question == 2) {
                  prev_2_sel = "";
                  prev_3_sel = "";
                  prev_4_sel = "";
                  percent = 25;
                  $("input#question_2_sel").val("");
                  $("input#question_3_sel").val("");
                  $("input#question_4_sel").val("");
                } else {
                  if (question == 3) {
                    prev_3_sel = "";
                    prev_4_sel = "";
                    percent = 50;
                    $("input#question_3_sel").val("");
                    $("input#question_4_sel").val("");
                  } else {
                    prev_4_sel = "";
                    percent = 75;
                    $("input#question_4_sel").val("");
                  }
                }
              }

              $(".text-wrapper").removeClass("bg-gray");
              $(".wheel-finder-wrapper").removeClass("bg-white");

              if ($(".wheel-finder-percentage").css("display") == "none")
                $(".wheel-finder-percentage").fadeIn();
              if ($(window).width() <= 767) {
                // if window with less or equal to 767 the inverted percentage bar and "width" will be changed
                $(".wheel-finder-percentage .progress div").css(
                  "width",
                  percent + "%"
                );
              } else {
                // else the percentage bar is vertical and "height" will be changed
                $(".wheel-finder-percentage .progress div").css(
                  "height",
                  percent + "%"
                );
              }
              $(".wheel-finder-percentage i").html(percent + " %");

              $("#question_" + question).fadeIn();
              $(".question-wrapper").slice(question).remove();
              $(".products-wrapper").hide();
              if (wheelFinderSwiper) wheelFinderSwiper.destroy(true, true);
              $(".products-wrapper #div_products").html("");
              $(".wheel-finder-wrapper .wheel-finder-steps span.step")
                .slice(question - 1)
                .html("")
                .removeClass("filled");

              if (question == 1) {
                $(".wheel-finder-wrapper .wheel-finder-steps").hide();
                $(".wheel-finder-wrapper .wheel-finder-percentage").removeClass(
                  "padd-top"
                );
              }
            }
          );

          // manage steps when clicking on back button
          $("body").on(
            "click",
            ".wheel-finder-wrapper .wheel-finder-steps button",
            function () {
              var id = $(
                ".wheel-finder-wrapper .wheel-finder-steps span.step.filled:eq(-1)"
              ).attr("id");

              $(".wheel-finder-wrapper .wheel-finder-steps span#" + id).trigger(
                "click"
              );
            }
          );

          function checkScrollPos() {
            var pos =
              $(".questions-cont").offset().top - $("header").innerHeight();
            if ($(this).scrollTop() > pos) {
              $(".wheel-finder-steps").addClass("fixed");
              if ($(".wheel-finder-steps").css("display") == "block")
                $(".questions-wrapper").addClass("padd-top");
              $(
                ".wheel-finder-wrapper:not(.bg-white) .wheel-finder-steps"
              ).addClass("other-bg");
            } else {
              $(".wheel-finder-steps").removeClass("fixed");
              if ($(".wheel-finder-steps").css("display") == "block")
                $(".questions-wrapper").removeClass("padd-top");
              $(
                ".wheel-finder-wrapper:not(.bg-white) .wheel-finder-steps"
              ).removeClass("other-bg");
            }
          }
          $(window).scroll(function () {
            checkScrollPos();
          });
          $(document).ready(function () {
            checkScrollPos();
          });
        }

        /* FOOTER MENU */
        if ($(window).width() < 768) {
          $("#footer .menu-wrapper h5").click(function () {
            $(this).toggleClass("active");
            $(this).parent().find("ul.nav").slideToggle();
            $(this).parent().find("a.contact-us").fadeToggle();
          });
        }

        /* NEWSLETTER BLOCK */
        /*if($('html').attr('lang') == 'pt-PT'){
          var emailMsg = 'Por favor insere o teu email.';
          var agreeMsg = 'Por favor confirma que concordas com os nossos termos e condições.';
        } else {
          var emailMsg = 'Please insert your email address.';
          var agreeMsg = 'Please confirm that you agree to our terms and conditions.';
        }
          
        $('#mc-embedded-subscribe-form').validate({
          rules : {
            EMAIL : 'required',
            accept : 'required',
          },
          messages: {
            EMAIL : emailMsg,
            accept : agreeMsg,
          },
          errorPlacement: function(error, element) {
            if ( element.attr("name") == "accept" ){
                error.appendTo("#checkbox-errorbox");
            } else {
              error.insertAfter(element);
            };
          }
        });

        var formEl = $('#mc-embedded-subscribe-form');

        function register($form) {
          if ($("#agree").prop("checked") == false) {
            $('#mc-result').html('Deve aceitar os termos');
            $('#mc-result').show();
          } else {
            $.ajax({
              type: formEl.attr('method'),
              url: formEl.attr('action'),
              data: formEl.serialize(),
              cache: false,
              dataType: 'json',
              contentType: "application/json; charset=utf-8",
              error: function (err) {
                alert("Could not connect to the registration server. Please try again later.");
              },
              success: function (data) {
                if (data.result != "success") {
                  // Something went wrong, do something to notify the user. maybe alert(data.msg);
                  console.log(data);
                  $('#mc-result').html(emailExists);
                  $('#mc-result').show();
                } else {
                  // It worked, carry on...
                  console.log(data);
                  $('#mc-result').html(emailSuccess);
                  $('#mc-result').show();
                }
              }
            });
          }
        }

        $(document).on('submit', '#mc-embedded-subscribe-form', function (event) {
          var $form = $(this);
          event.preventDefault();
          register($form);
        });*/
      },
    },
    // Home page
    home: {
      init: function () {
        // JavaScript to be fired on the home page
        $(document).on("mousemove", function (e) {
          $(".dirt li:nth-child(1)").offset({
            left: e.pageX / 50,
            top: e.pageY / 90,
          });
          $(".dirt li:nth-child(2)").offset({
            left: e.pageX / 60,
            top: e.pageY / 70,
          });
          $(".dirt li:nth-child(3)").offset({
            left: e.pageX / 30,
            top: e.pageY / 95,
          });
        });
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
        var bannerSwiper = new Swiper("#banner-slides", {
          noSwiping: true,
          speed: 800,
          loop: false,
          watchOverflow: true,
          autoplay: {
            delay: 10000,
          },
          effect: "fade",
          breakpoints: {
            768: {
              noSwiping: true,
            },
          },
          pagination: {
            el: "#banner-wrap .swiper-pagination",
            clickable: "true",
          },
          navigation: {
            nextEl: "#banner-wrap .arrow-next",
            prevEl: "#banner-wrap .arrow-prev",
          },
        });

        var homebloglist = new Swiper(".home-blog-list", {
          pagination: {
            el: "#article-list .swiper-pagination",
          },
          slidesPerView: 3,
          watchOverflow: true,
          breakpoints: {
            320: {
              slidesPerView: 1,
              spaceBetween: 16,
            },
            1000: {
              slidesPerView: 3,
              spaceBetween: 32,
            },
          },
        });

        var featuredSwiper = new Swiper(".featured-list", {
          watchOverflow: true,
          pagination: {
            el: ".featured-list .swiper-pagination",
          },
          navigation: {
            nextEl: ".featured-list .swiper-button-next",
            prevEl: ".featured-list .swiper-button-prev",
          },
          breakpoints: {
            320: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            576: {
              slidesPerView: 2,
              spaceBetween: 8,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 16,
            },
            1000: {
              slidesPerView: 4,
              spaceBetween: 32,
            },
          },
        });
      },
    },
    // Product detail page
    single_product: {
      init: function () {},
      finalize: function () {
        $(".variations_form select").select2({
          minimumResultsForSearch: -1,
          dropdownParent: $("div.entry-summary"),
        });

        // gallery
        /*$('.grid').masonry({
          // options
          itemSelector: '.grid-item',
          percentPosition: true
        });*/

        var product_type = $("input[name='product_type']").val();

        function getSpecifications() {
          var variation_id = $("input[name='variation_id']").val();
          var product_id = $("input[name='custom_product_id']").val();
          var product_cats = $("input[name='product_cats']").val();

          if (
            (product_type == "variable" && variation_id > 0) ||
            product_type != "variable"
          ) {
            var data = {
              action: "get_specifications",
              post_type: "POST",
              id: variation_id,
              product: product_id,
              cats: product_cats,
            };

            $.post(ajaxurl, data, function (response) {
              $("#div_specifications").html(response);
            });
          }
        }

        if (product_type == "simple") {
          getSpecifications();
        } else {
          $("input[name='variation_id']").on("change", function () {
            getSpecifications();
          });
        }
      },
    },
    archive: {
      init: function () {},
      finalize: function () {
        /*function filterClick() {
          var transition = "fade";
          var speed = 250;
          var data = null;

          var data_meta_key = "";
          var data_meta_value = "";
          var data_meta_compare = "";
          var data_meta_type = "";
          var data_taxonomy = $("#current_taxonomy").data("taxonomy");
          var data_taxonomy_terms =
          $("#current_taxonomy").data("taxonomy-terms");
          var data_taxonomy_operator =
          $("#current_taxonomy").data("taxonomy-operator");

          var precos = $("#min-amount").val() + "," + $("#max-amount").val();
          data_meta_key = $("#price_loadmore").data("meta-key");
          data_meta_value = precos;
          data_meta_compare = $("#price_loadmore").data("meta-compare");
          data_meta_type = $("#price_loadmore").data("meta-type");

          $(".option select.dropdown").each(function () {
            var taxonomy = $(this).find(":selected").data("taxonomy");

            if (taxonomy == "tipo") {
              if (data_taxonomy.indexOf(":tipo") === -1) {
                data_taxonomy += ":tipo";
                data_taxonomy_terms +=
                ":" + $(this).find(":selected").data("taxonomy-terms");
                data_taxonomy_operator += ":IN";
              } else {
                data_taxonomy_terms +=
                "," + $(this).find(":selected").data("taxonomy-terms");
              }
            } else if (taxonomy == "pa_rim-diameter") {
              if (data_taxonomy.indexOf(":pa_rim-diameter") === -1) {
                data_taxonomy += ":pa_rim-diameter";
                data_taxonomy_terms +=
                ":" + $(this).find(":selected").data("taxonomy-terms");
                data_taxonomy_operator += ":IN";
              } else {
                data_taxonomy_terms +=
                "," + $(this).find(":selected").data("taxonomy-terms");
              }
            } else if (taxonomy == "material") {
              if (data_taxonomy.indexOf(":material") === -1) {
                data_taxonomy += ":material";
                data_taxonomy_terms +=
                ":" + $(this).find(":selected").data("taxonomy-terms");
                data_taxonomy_operator += ":IN";
              } else {
                data_taxonomy_terms +=
                "," + $(this).find(":selected").data("taxonomy-terms");
              }
            } else if (taxonomy == "inner_width") {
              if (data_taxonomy.indexOf(":inner_width") === -1) {
                data_taxonomy += ":inner_width";
                data_taxonomy_terms +=
                ":" + $(this).find(":selected").data("taxonomy-terms");
                data_taxonomy_operator += ":IN";
              } else {
                data_taxonomy_terms +=
                "," + $(this).find(":selected").data("taxonomy-terms");
              }
            } else if (taxonomy == "pa_brake-interface") {
              if (data_taxonomy.indexOf(":pa_brake-interface") === -1) {
                data_taxonomy += ":pa_brake-interface";
                data_taxonomy_terms +=
                ":" + $(this).find(":selected").data("taxonomy-terms");
                data_taxonomy_operator += ":IN";
              } else {
                data_taxonomy_terms +=
                "," + $(this).find(":selected").data("taxonomy-terms");
              }
            } else if (taxonomy == "pa_axle-system") {
              if (data_taxonomy.indexOf(":pa_axle-system") === -1) {
                data_taxonomy += ":pa_axle-system";
                data_taxonomy_terms +=
                ":" + $(this).find(":selected").data("taxonomy-terms");
                data_taxonomy_operator += ":IN";
              } else {
                data_taxonomy_terms +=
                "," + $(this).find(":selected").data("taxonomy-terms");
              }
            }
          });

          // retira evento click dos filtros enquanto atualiza
          $("span.tab-content-option").each(function () {
            $(this).css("pointer-events", "none");
          });

          // ordenação
          var orderby = $("#ordering").find(":selected").data("orderby");
          var order = $("#ordering").find(":selected").data("order");
          data = {
            metaKey: data_meta_key,
            metaValue: data_meta_value,
            metaCompare: data_meta_compare,
            metaType: data_meta_type,
            taxonomy: data_taxonomy,
            taxonomyTerms: data_taxonomy_terms,
            taxonomyOperator: data_taxonomy_operator,
            orderby: orderby,
            order: order,
          };

          // Call Ajax Load More `filter` method
          ajaxloadmore.filter(transition, speed, data);
        }

        var current_language = $("#current_language").val();
        window.almComplete = function (alm) {
          //console.log(alm.ajax.dataset);
          var totalposts = alm.totalposts;
          if (totalposts) {
            var text = "";
            if (current_language == "pt") {
              if (totalposts > 1) text = "Resultados";
              else text = "Resultado";
            } else {
              if (totalposts > 1) text = "Results";
              else text = "Result";
            }

            $(".total_products")
            .html('<span class="total">' + alm.totalposts + "</span> " + text)
            .fadeIn();
          } else {
            $(".total_products").html("");
          }
        };

        $(".option select.dropdown").select2({
          minimumResultsForSearch: -1,
        });

        $(".option select.dropdown").on("change", function () {
          filterClick();
        });

        $("#ordering").change(function () {
          filterClick();
        });

        // slider de preços
        var activityTimeout;

        function inActive() {
          filterClick();
        }

        function resetActive() {
          clearTimeout(activityTimeout);
          activityTimeout = setTimeout(inActive, 2000);
        }

        var minRange = Math.floor($("#min-amount").val());
        var maxRange = Math.ceil($("#max-amount").val());
        var minPrice = Math.floor($("#min-amount").val());
        var maxPrice = Math.ceil($("#max-amount").val());
        $("#slider-range").slider({
          range: true,
          min: minPrice,
          max: maxPrice,
          values: [minRange, maxRange],
          slide: function (event, ui) {
            for (var i = 0; i < ui.values.length; ++i) {
              $("input.price-range-input[data-index=" + i + "]").val(
                ui.values[i]
                );
              resetActive();
            }
          },
        });*/

        // compare
        var checkboxes = $(".check-compare");
        var checkedAmt;

        function checkAmount() {
          checkedAmt = $(
            ".compare-block input[type='checkbox']:checked"
          ).length;
          console.log(checkedAmt);
        }

        if ($("html").attr("lang") == "pt-PT") {
          var chooseMore = "Escolhe mais itens para comparar";
          var compareBtn = "Comparar";
          var compareMax = "Só podes escolher até 3 items";
        } else {
          var chooseMore = "Choose more items to compare";
          var compareBtn = "Compare";
          var compareMax = "You can only compare up to 3 items";
        }

        function verifyCompare() {
          checkAmount();
          if (checkedAmt < 1) {
            $("#compare").hide();
            $("#compare-button").hide();
            $("#compare-button").removeClass("notready");
            $("#compare-button").removeClass("ready");
          } else if (checkedAmt == 1) {
            $("#compare").show();
            $("#compare-button").show();
            $("#compare-button .btn-text").html(chooseMore);
            $("#compare-button").addClass("notready");
            $("#compare-button").removeClass("ready");
          } else if (checkedAmt <= 3) {
            $("#compare").show();
            $("#compare-button .btn-text").html(compareBtn);
            $("#compare-button").removeClass("notready");
            $("#compare-button").addClass("ready");
          } else {
            $("#compare-button .btn-text").html(compareMax);
            $("#compare-button").addClass("notready");
            $("#compare-button").removeClass("ready");
          }
        }

        verifyCompare();

        $(document).on("change", ".check-compare", function () {
          verifyCompare();
        });

        $("#compare-button").click(function (e) {
          e.preventDefault();
          var destination = $(this).attr("href");
          if (checkedAmt > 1 && checkedAmt < 4) {
            $(".check-compare:checkbox:checked").each(function () {
              var prodID = $(this).attr("product-id");
              destination += prodID + ",";
            });
            var compareURL = destination.slice(0, -1);
            //window.location = compareURL;
            //window.open(compareURL, '_blank');
            window.location.href = compareURL;
          }
        });

        // open popup with specifications
        $(".product-wrapper.specs a").click(function () {
          var product_type = $(this)
            .parent()
            .find("input[name='product_type']")
            .val();
          var variation_id = $(this)
            .parent()
            .find("input[name='variation_id']")
            .val();
          var product_id = $(this)
            .parent()
            .find("input[name='custom_product_id']")
            .val();
          var product_cats = $(this)
            .parent()
            .find("input[name='product_cats']")
            .val();
          var content = $("#div_specifications_" + product_id).html();

          if (!content) {
            if (
              (product_type == "variable" && variation_id > 0) ||
              product_type != "variable"
            ) {
              var data = {
                action: "get_specifications",
                post_type: "POST",
                id: variation_id,
                product: product_id,
                cats: product_cats,
              };

              $.post(ajaxurl, data, function (response) {
                $("#div_specifications_" + product_id).html(response);
                $("#specs_product_" + product_id).modal({
                  keyboard: true,
                  show: true,
                });
              });
            }
          } else {
            $("#specs_product_" + product_id).modal({
              keyboard: true,
              show: true,
            });
          }
        });

        /**
         *
         * Categories
         *
         */

        var categoryModalitiesSwiper = new Swiper(".modalities-wrapper", {
          watchOverflow: true,
          pagination: {
            el: ".modalities-wrapper .swiper-pagination",
          },
          navigation: {
            nextEl: ".modalities-wrapper .swiper-button-next",
            prevEl: ".modalities-wrapper .swiper-button-prev",
          },
          breakpoints: {
            320: {
              slidesPerView: 1,
              slidesPerGroup: 1,
              spaceBetween: 0,
            },
            576: {
              slidesPerView: 2,
              slidesPerGroup: 1,
              spaceBetween: 8,
            },
            768: {
              slidesPerView: 3,
              slidesPerGroup: 2,
              spaceBetween: 16,
            },
            1000: {
              slidesPerView: 4,
              slidesPerGroup: 3,
              spaceBetween: 32,
            },
          },
        });

        /**
         *
         * Modalities
         *
         */

        var modalityRangesSwiper = new Swiper(".modality-ranges-list", {
          watchOverflow: true,
          pagination: {
            el: ".modality-ranges-list .swiper-pagination",
          },
          navigation: {
            nextEl: ".modality-ranges-list .swiper-button-next",
            prevEl: ".modality-ranges-list .swiper-button-prev",
          },
          breakpoints: {
            320: {
              slidesPerView: 1,
              slidesPerGroup: 1,
              spaceBetween: 0,
            },
            576: {
              slidesPerView: 2,
              slidesPerGroup: 1,
              spaceBetween: 8,
            },
            768: {
              slidesPerView: 3,
              slidesPerGroup: 2,
              spaceBetween: 16,
            },
            1000: {
              slidesPerView: 4,
              slidesPerGroup: 3,
              spaceBetween: 32,
            },
          },
        });

        /**
         *
         * Ranges
         *
         */

        var rangeProductsSwiper = new Swiper(".range-products-list", {
          watchOverflow: true,
          pagination: {
            el: ".range-products-list .swiper-pagination",
          },
          navigation: {
            nextEl: ".range-products-list .swiper-button-next",
            prevEl: ".range-products-list .swiper-button-prev",
          },
          breakpoints: {
            320: {
              slidesPerView: 1,
              slidesPerGroup: 1,
              spaceBetween: 0,
            },
            576: {
              slidesPerView: 2,
              slidesPerGroup: 1,
              spaceBetween: 8,
            },
            768: {
              slidesPerView: 3,
              slidesPerGroup: 2,
              spaceBetween: 16,
            },
            1000: {
              slidesPerView: 4,
              slidesPerGroup: 3,
              spaceBetween: 32,
            },
          },
        });

        /* range images and thumbnails swiper */
        var rangeThumbsSwiper = new Swiper(".range-thumb-images", {
          direction: "vertical",
          loop: false,
          slidesPerView: 5,
        });

        var rangeSwiper = new Swiper(".range-images", {
          loop: false,
          thumbs: {
            swiper: rangeThumbsSwiper,
          },
        });
        /* range images and thumbnails swiper */

        /**
         *
         * Collections
         *
         */

        var collectionRangesSwiper = new Swiper(".collection-ranges-list", {
          watchOverflow: true,
          pagination: {
            el: ".collection-ranges-list .swiper-pagination",
          },
          navigation: {
            nextEl: ".collection-ranges-list .swiper-button-next",
            prevEl: ".collection-ranges-list .swiper-button-prev",
          },
          breakpoints: {
            320: {
              slidesPerView: 1,
              slidesPerGroup: 1,
              spaceBetween: 0,
            },
            576: {
              slidesPerView: 2,
              slidesPerGroup: 1,
              spaceBetween: 8,
            },
            768: {
              slidesPerView: 3,
              slidesPerGroup: 2,
              spaceBetween: 16,
            },
            1000: {
              slidesPerView: 4,
              slidesPerGroup: 3,
              spaceBetween: 32,
            },
          },
        });

        /**
         *
         * Technologies
         *
         */
        var technology_page = $("#technology_page").val();

        // V1
        if ($(".items-wrapper.v1").length > 0) {
          items_per_page_v1 = 3;

          $("body").on("click", ".items-wrapper.v1 a.loadmore", function () {
            var page = $("#items_v1_page").val();
            var total = $(this).data("total");

            var data = {
              action: "get_technology_items_v1",
              post_type: "POST",
              page: page,
              technology_page: technology_page,
            };

            $.post(ajaxurl, data, function (response) {
              $("#div_items_results_v1").append(response);
              page++;
              $("#items_v1_page").val(page);
              if (page * items_per_page_v1 >= total) {
                $(".items-wrapper.v1 a.loadmore").fadeOut();
              }
              AOS.init();
            });
          });
        }

        // V2
        if ($(".items-wrapper.v2").length > 0) {
          items_per_page_v2 = 3;

          $("body").on("click", ".items-wrapper.v2 a.loadmore", function () {
            var page = $("#items_v2_page").val();
            var total = $(this).data("total");

            var data = {
              action: "get_technology_items_v2",
              post_type: "POST",
              page: page,
              technology_page: technology_page,
            };

            $.post(ajaxurl, data, function (response) {
              $("#div_items_results_v2").append(response);
              page++;
              $("#items_v2_page").val(page);
              if (page * items_per_page_v2 >= total) {
                $(".items-wrapper.v2 a.loadmore").fadeOut();
              }
              AOS.init();
            });
          });
        }

        /* v2 gallery swiper */
        var technologiesSwiper = new Swiper(".technology-images", {
          slidesPerView: 2,
          spaceBetween: 20,
          loop: false,
          navigation: {
            nextEl: ".images-wrapper .arrow-next",
            prevEl: ".images-wrapper .arrow-prev",
          },
          pagination: {
            el: ".swiper-pagination",
            clickable: "true",
          },
          breakpoints: {
            320: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            1000: {
              slidesPerView: 2,
              spaceBetween: 32,
            },
          },
        });

        /* posts categories and tags */
        var paged = 1;

        var posts_per_page = 6;
        $("body").on("click", "#article-list a.loadmore", function () {
          var category = $(".filter-wrapper a.filter.active").data("cat");
          var tag = $(".filter-wrapper a.filter.active").data("tag");
          var total = $(this).data("total");

          var data = {
            action: "get_more_posts",
            post_type: "POST",
            category: category,
            tag: tag,
            paged: paged,
          };

          $.post(ajaxurl, data, function (response) {
            $("#article-list #div_results_posts").append(response);
            paged++;
            if (paged * posts_per_page >= total) {
              $("#article-list a.loadmore").fadeOut();
            }
          });
        });

        /**
         *
         * Tags
         *
         */
        var posts_per_page = 4;
        $("body").on("click", ".products-wrapper a.loadmore", function () {
          var tag = $("input[name=tag_sel]").val();
          var total = $(this).data("total");

          var data = {
            action: "get_more_tag_products",
            post_type: "POST",
            tag: tag,
            paged: paged,
          };

          $.post(ajaxurl, data, function (response) {
            $(".products-wrapper #div_results_posts").append(response);
            paged++;
            if (paged * posts_per_page >= total) {
              $(".products-wrapper a.loadmore").fadeOut();
            }
          });
        });
      },
    },
    page_template_tpl_compare: {
      init: function () {},
      finalize: function () {
        if ($(window).width() < 769 && $(window).width() > 499) {
          $(".compare-column").each(function () {
            $(this).removeClass("col-md-4");
            $(this).addClass("col-md-6");
          });
        }
        if ($(window).width() < 769) {
          var relProdSwiper = new Swiper("#compare-slider", {
            speed: 300,
            loop: false,
            slidesPerView: 2,
            pagination: {
              el: ".swiper-pagination",
              clickable: "true",
            },
            breakpoints: {
              320: {
                slidesPerView: 1,
              },
              1000: {
                slidesPerView: 2,
              },
            },
          });
        }
      },
    },
    page_template_tpl_downloads: {
      init: function () {},
      finalize: function () {
        //$('.filter-selector:first-child .filter-item-toggle').addClass('active');
        var paged = 1;

        function filterDocuments(category) {
          var data = {
            action: "get_documents",
            post_type: "POST",
            category: category,
          };

          $.post(ajaxurl, data, function (response) {
            var spl = response.split(";;");
            $(".documents-wrapper #div_results").html(spl[1]);
            paged = 1;
          });
        }

        $(".filter-wrapper a.filter").click(function (e) {
          e.preventDefault();

          var category = $(this).data("cat");
          $(".filter-wrapper a.filter").removeClass("active");
          $(this).addClass("active");
          filterDocuments(category);
        });

        /*filterDownloads();

        $('.user-toggle').click(function () {
          $('span.logout').toggle(200);
        })

        $('.filter-item-toggle').click(function () {
          if ($(this).hasClass('active')) {
            $(this).removeClass('active');
          } else {
            $('.filter-item-toggle').removeClass('active');
            $(this).addClass('active');
          }
          filterDownloads();
        })*/
      },
    },
    page_template_tpl_private: {
      init: function () {},
      finalize: function () {
        $(".filter-selector:first-child .filter-item-toggle").addClass(
          "active"
        );

        filterDownloads();

        $(".user-toggle").click(function () {
          $("span.logout").toggle(200);
        });

        $(".filter-item-toggle").click(function () {
          if ($(this).hasClass("active")) {
            $(this).removeClass("active");
          } else {
            $(".filter-item-toggle").removeClass("active");
            $(this).addClass("active");
          }
          filterDownloads();
        });
      },
    },
    single_teams: {
      init: function () {},
      finalize: function () {
        var team_page = $("#team_page").val();

        if ($(".members-wrapper").length > 0) {
          members_per_page = 3;
          $("body").on("click", ".members-wrapper a.loadmore", function () {
            var page = $("#members_page").val();
            var total = $(this).data("total");

            var data = {
              action: "get_team_members",
              post_type: "POST",
              page: page,
              team_page: team_page,
            };

            $.post(ajaxurl, data, function (response) {
              $("#div_members_results").append(response);
              page++;
              $("#members_page").val(page);
              if (page * members_per_page >= total) {
                $(".members-wrapper a.loadmore").fadeOut();
              }
            });
          });
        }

        if ($(".events-wrapper").length > 0) {
          events_per_page = 6;
          $("body").on("click", ".events-wrapper a.loadmore", function () {
            var page = $("#events_page").val();
            var total = $(this).data("total");

            var data = {
              action: "get_team_events",
              post_type: "POST",
              page: page,
              team_page: team_page,
            };

            $.post(ajaxurl, data, function (response) {
              $("#div_events_results").append(response);
              page++;
              $("#events_page").val(page);
              if (page * events_per_page >= total) {
                $(".events-wrapper a.loadmore").fadeOut();
              }
            });
          });
        }
      },
    },
    page_template_tpl_products: {
      init: function () {},
      finalize: function () {
        var paged = 1;

        function filterProducts(family) {
          // build url with selected filters
          var actual_url = window.location.href;
          var actual_url = actual_url.substr(
            0,
            actual_url.lastIndexOf("/") + 1
          );
          if (family) actual_url += "?family=" + family;

          // replace browser history
          history.replaceState(null, "products", actual_url);

          var data = {
            action: "get_products",
            post_type: "POST",
            family: family,
          };

          $.post(ajaxurl, data, function (response) {
            var spl = response.split(";;");
            $(".products-wrapper #div_results").html(spl[1]);
            if (!parseInt(spl[0])) {
              $(".products-wrapper a.loadmore").fadeOut();
            } else {
              $(".products-wrapper a.loadmore").fadeIn();
            }
            paged = 1;
          });
        }

        $(".filter-wrapper a.filter").click(function (e) {
          e.preventDefault();

          var family = $(this).data("fam");
          $(".filter-wrapper a.filter").removeClass("active");
          $(this).addClass("active");
          filterProducts(family);
        });

        var posts_per_page = 4;
        $("body").on("click", ".products-wrapper a.loadmore", function () {
          var family = $(".filter-wrapper a.filter.active").data("fam");
          var total = $(this).data("total");

          var data = {
            action: "get_more_products",
            post_type: "POST",
            family: family,
            paged: paged,
          };

          $.post(ajaxurl, data, function (response) {
            $(".products-wrapper #div_results_posts").append(response);
            paged++;
            if (paged * posts_per_page >= total) {
              $(".products-wrapper a.loadmore").fadeOut();
            }
          });
        });

        // when loading verify if url has family parameter and if so filter products
        var query_params = location.href.split("?");
        if (query_params[1]) {
          var spl = query_params[1].split("=");

          if (spl[0] == "family") {
            $(".filter-wrapper a.filter[data-fam='" + spl[1] + "']").trigger(
              "click"
            );
          }
        }

        // compare
        var checkboxes = $(".check-compare");
        var checkedAmt;

        function checkAmount() {
          checkedAmt = $(
            ".compare-block input[type='checkbox']:checked"
          ).length;
          console.log(checkedAmt);
        }

        if ($("html").attr("lang") == "pt-PT") {
          var chooseMore = "Escolhe mais itens para comparar";
          var compareBtn = "Comparar";
          var compareMax = "Só podes escolher até 3 items";
        } else {
          var chooseMore = "Choose more items to compare";
          var compareBtn = "Compare";
          var compareMax = "You can only compare up to 3 items";
        }

        function verifyCompare() {
          checkAmount();
          if (checkedAmt < 1) {
            $("#compare").hide();
            $("#compare-button").hide();
            $("#compare-button").removeClass("notready");
            $("#compare-button").removeClass("ready");
          } else if (checkedAmt == 1) {
            $("#compare").show();
            $("#compare-button").show();
            $("#compare-button .btn-text").html(chooseMore);
            $("#compare-button").addClass("notready");
            $("#compare-button").removeClass("ready");
          } else if (checkedAmt <= 3) {
            $("#compare").show();
            $("#compare-button .btn-text").html(compareBtn);
            $("#compare-button").removeClass("notready");
            $("#compare-button").addClass("ready");
          } else {
            $("#compare-button .btn-text").html(compareMax);
            $("#compare-button").addClass("notready");
            $("#compare-button").removeClass("ready");
          }
        }

        verifyCompare();

        $(document).on("change", ".check-compare", function () {
          verifyCompare();
        });

        $("#compare-button").click(function (e) {
          e.preventDefault();
          var destination = $(this).attr("href");
          if (checkedAmt > 1 && checkedAmt < 4) {
            $(".check-compare:checkbox:checked").each(function () {
              var prodID = $(this).attr("product-id");
              destination += prodID + ",";
            });
            var compareURL = destination.slice(0, -1);
            //window.location = compareURL;
            //window.open(compareURL, '_blank');
            window.location.href = compareURL;
          }
        });

        // open popup with specifications
        $(document).on("click", ".product-wrapper.specs a", function () {
          var product_type = $(this)
            .parent()
            .find("input[name='product_type']")
            .val();
          var variation_id = $(this)
            .parent()
            .find("input[name='variation_id']")
            .val();
          var product_id = $(this)
            .parent()
            .find("input[name='custom_product_id']")
            .val();
          var product_cats = $(this)
            .parent()
            .find("input[name='product_cats']")
            .val();
          var content = $("#div_specifications_" + product_id).html();

          if (!content) {
            if (
              (product_type == "variable" && variation_id > 0) ||
              product_type != "variable"
            ) {
              var data = {
                action: "get_specifications",
                post_type: "POST",
                id: variation_id,
                product: product_id,
                cats: product_cats,
              };

              $.post(ajaxurl, data, function (response) {
                $("#div_specifications_" + product_id).html(response);
                $("#specs_product_" + product_id).modal({
                  keyboard: true,
                  show: true,
                });
              });
            }
          } else {
            $("#specs_product_" + product_id).modal({
              keyboard: true,
              show: true,
            });
          }
        });
      },
    },
    page_template_tpl_news_events: {
      init: function () {},
      finalize: function () {
        var paged = 1;

        var posts_per_page = 6;
        $("body").on("click", "#article-list a.loadmore", function () {
          var category = $(".filter-wrapper a.filter.active").data("cat");
          var total = $(this).data("total");

          var data = {
            action: "get_more_posts",
            post_type: "POST",
            category: category,
            paged: paged,
          };

          $.post(ajaxurl, data, function (response) {
            $("#article-list #div_results_posts").append(response);
            paged++;
            if (paged * posts_per_page >= total) {
              $("#article-list a.loadmore").fadeOut();
            }
          });
        });
      },
    },
    page_template_tpl_dealers: {
      init: function () {},
      finalize: function () {
        var paged = 1;

        function filterRegions(region) {
          var data = {
            action: "get_dealers",
            post_type: "POST",
            region: region,
          };

          $.post(ajaxurl, data, function (response) {
            var spl = response.split(";;");
            $(".distributors-list #div_results").html(spl[1]);
            paged = 1;
          });
        }

        $(".filter-wrapper a.filter").click(function (e) {
          e.preventDefault();

          var region = $(this).data("region");
          $(".filter-wrapper a.filter").removeClass("active");
          $(this).addClass("active");
          filterRegions(region);
        });

        var posts_per_page = 12;
        $("body").on("click", ".distributors-list a.loadmore", function () {
          var region = $(".filter-wrapper a.filter.active").data("region");
          var total = $(this).data("total");

          var data = {
            action: "get_more_dealers",
            post_type: "POST",
            region: region,
            paged: paged,
          };

          $.post(ajaxurl, data, function (response) {
            $(".distributors-list #div_results_posts").append(response);
            paged++;
            if (paged * posts_per_page >= total) {
              $(".distributors-list a.loadmore").fadeOut();
            }
          });
        });
      },
    },
    page_template_tpl_wheel_finder: {
      init: function () {},
      finalize: function () {},
    },
    page_template_tpl_about: {
      init: function () {},
      finalize: function () {
        $(
          "#about-text h2, #about-text h3, #about-text h4, #about-text h5, #about-text h6, #about-text p, #about-text ul"
        ).attr("data-aos", "fade-up");
        $("#about-text img").attr("data-aos", "zoom-in");
        AOS.refresh();
      },
    },
    single_post: {
      init: function () {},
      finalize: function () {
        $(
          ".post-content h2, .post-content h3, .post-content h4, .post-content h5, .post-content h6, .post-content p, .post-content ul"
        ).attr("data-aos", "fade-up");
        $(".post-content img").attr("data-aos", "zoom-in");
        AOS.refresh();
      },
    },
    page_template_tpl_politicas: {
      init: function () {},
      finalize: function () {
        $(
          ".textos h2, .textos h3, .textos h4, .textos h5, .textos h6, .textos p, .textos ul"
        ).attr("data-aos", "fade-up");
        $(".textos img").attr("data-aos", "zoom-in");
        AOS.refresh();
      },
    },
    page_template_tpl_technologies: {
      init: function () {},
      finalize: function () {
        var ourTechSwiper = new Swiper(".our-tech-wrapper", {
          watchOverflow: true,
          pagination: {
            el: ".our-tech-wrapper .swiper-pagination",
          },
          navigation: {
            nextEl: ".our-tech-wrapper .swiper-button-next",
            prevEl: ".our-tech-wrapper .swiper-button-prev",
          },
          breakpoints: {
            320: {
              slidesPerView: 1,
              slidesPerGroup: 1,
              spaceBetween: 0,
            },
            576: {
              slidesPerView: 2,
              slidesPerGroup: 1,
              spaceBetween: 8,
            },
            768: {
              slidesPerView: 3,
              slidesPerGroup: 2,
              spaceBetween: 16,
            },
            1000: {
              slidesPerView: 4,
              slidesPerGroup: 3,
              spaceBetween: 32,
            },
          },
        });

        var ourInovSwiper = new Swiper(".our-inov-wrapper", {
          watchOverflow: true,
          pagination: {
            el: ".our-inov-wrapper .swiper-pagination",
          },
          navigation: {
            nextEl: ".our-inov-wrapper .swiper-button-next",
            prevEl: ".our-inov-wrapper .swiper-button-prev",
          },
          breakpoints: {
            320: {
              slidesPerView: 1,
              slidesPerGroup: 1,
              spaceBetween: 0,
            },
            576: {
              slidesPerView: 2,
              slidesPerGroup: 1,
              spaceBetween: 8,
            },
            768: {
              slidesPerView: 3,
              slidesPerGroup: 2,
              spaceBetween: 16,
            },
            1000: {
              slidesPerView: 4,
              slidesPerGroup: 3,
              spaceBetween: 32,
            },
          },
        });
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, "finalize");
        }
      );

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  function filterDownloads() {
    $(".filter-item-toggle").each(function () {
      if ($(this).hasClass("active")) {
        var activeCat = $(this).attr("data-filter");
        $(".document-item").each(function () {
          if ($(this).attr("data-category") === activeCat) {
            $(this).addClass("active");
          } else {
            $(this).removeClass("active");
          }
        });
      } else if ($(".filter-item-toggle.active").length < 1) {
        $(".document-item").addClass("active");
      }
    });
  }

  var newsslider = new Swiper(".news-blog-list", {
    pagination: {
      el: ".most-popular-wrapper .swiper-pagination",
    },
    slidesPerView: 3,
    watchOverflow: true,
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 16,
      },
      1000: {
        slidesPerView: 3,
        spaceBetween: 32,
      },
    },
  });

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
